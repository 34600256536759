// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';

import styles from './style.module.scss';

IpxContainer.defaultProps = {
    background: '#fff',
};
/**
 * iphoneX底部适配 有增加fixed布局
 * children：需要加安全距离元素
 * background：安全距离间隙颜色
 * @param {*} props
 */
export default function IpxContainer(props) {
    const { children, background, zIndex, noBorder } = props;
    const borderStyle = noBorder ? { border: 0 } : {};
    return (
        <footer className={styles['m-ipx-wrapper']} style={{ background, zIndex }}>
            <div className={styles['m-ipx']} style={borderStyle}>{children}</div>
        </footer>
    );
}
